import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';

import agencyNoImg from "../../assets/agency.png";
import api from "../../services/api";
import authService from "../../services/auth.service";
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";

library.add(faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile, faGlobe);

const ViewAgency = () => {
    let history = useHistory();
    const GOOGLE_API = "AIzaSyCRW_RTMo8z7rUZ2GaxdiaRu42lhPMFj6g";
    
    // eslint-disable-next-line no-unused-vars
    const [originalAgencyName, setOriginalAgencyName] = useState("");

    const [agency, setAgency] = useState({
        name:"",
        locationinput: "",
        selectedImage: null,
        agencyId: 0,
        addressId: 0,
        latitude: 0.0,
        longitude: 0.0
    });


    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get('id');
    const modeId = queryParams.get('modeid');

    const getAgency = async () => {
        api.get("/agency/getAgency?agency=" + agencyId, authService.getToken())
            .then(response => {
                setAgency({
                    ...agency,
                    name: response.data.name,
                    locationinput: response.data.address.address1,
                    addressId: response.data.address.googlePlaceId,
                    latitude: response.data.address.latitude,
                    longitude: response.data.address.longitude,
                    agencyId: response.data.id,
                    phone: response.data.phone ? response.data.phone:"",
                    email: response.data.email ? response.data.email : "",
                    url: response.data.url ? response.data.url : "",
                    manualrequest: response.data.manageRequest,
                    img:response.data.picture.path,
                    bulkrequest: response.data.bulkRequest

                });
                setOriginalAgencyName(response.data.name.toLowerCase());
            });
            
    }

    const onChangeManualRequests = e => {
        // setAgency({
        //     ...agency,
        //     manualrequest: !agency.manualrequest

        // });
    }

    const onChangeBulkRequest = e => {
        // setAgency({
        //     ...agency,
        //     bulkrequest: !agency.bulkrequest

        // });
    }

    const CancelFnc = () => {
        if(modeId === '1') {
            history.push("/home");
        } else {
            history.push("/agencies");
        }
        
    }

    if (agencyId !== undefined && agencyId > 0)
    {
        getAgency();
    }

    return (
        <>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="card-container">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-titleagency">View Agency</h5>
                                <div className="row centerimg">
                                    {agency.img && !agency.selectedImage &&
                                        <img alt="" src={agency.img} className="iconagency" />
                                    }
                                    {!agency.img && !agency.selectedImage &&
                                        <img alt="" src={agencyNoImg} className="iconagency" />
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Name</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faInfo} /></span>
                                            </div>
                                            <input type="text" disabled={true} className="form-control" value={agency.name}  aria-label="Name" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Location</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faLocationArrow} /></span>
                                            </div>
                                            <Autocomplete
                                                disabled={true}
                                                apiKey={GOOGLE_API}
                                                className="form-control"
                                                placeholder="Enter Agency's location"
                                                defaultValue={agency.locationinput}
                                                required
                                                options={{componentRestrictions: { country: "us" },}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Phone</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faMobile} /></span>
                                            </div>
                                            <InputMask disabled={true} placeholder="Agency's Phone" value={agency.phone} className="form-control" mask="+1\ 999 999 9999" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Email</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faEnvelope} /></span>
                                            </div>
                                            <input id="email" name="email" disabled={true} value={agency.email} required type="email" className="form-control" placeholder="Agency's E-mail" aria-label="Username" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Url</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faGlobe} /></span>
                                            </div>
                                            <input type="url"  value={agency.url} disabled={true} className="form-control" placeholder="Agency's url" aria-label="URL" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb"> Admin settings</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9"> 
                                        <label className="lb"> Manual join requests</label> 
                                    </div>
                                    <div className="col-3 toggle-switch"> 
                                        <input disabled={true} type="checkbox" checked={agency.manualrequest} className="toggle-switch-1" /> 
                                        <label disabled={true} onClick={onChangeManualRequests}></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9"> 
                                        <label className="lb"> Bulk processing of requests</label> 
                                    </div>
                                    <div className="col-3 toggle-switch"> 
                                        <input disabled type="checkbox" id="topping" name="topping" value="Paneer" checked={agency.bulkrequest} className="toggle-switch-1" /> 
                                        <label onClick={onChangeBulkRequest}></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-secondary btn-fullx" onClick={CancelFnc} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewAgency